import React, {useEffect, useState} from 'react';
import {Col, Form, FormGroup, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import {TableCard} from "../../../generic/cards";
import {handleInputChange} from "../../../../lib/utils/handleInputChange";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {useHistory, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {Loader} from "../../../generic/loader";
import {TextControllerRHF} from "../../../generic/fields";
import {Promocode} from "../../index";
import dayjs from "dayjs";
import {withBreadcrumbs} from "../../../common/hoc/Breadcrumbs";
import {ENTITIES, useRoleSystem} from "../../../../lib/utils/hooks/useRoleSystem";
import {ElementWithPermissions} from "../../../common/formElements";
import {t} from "i18next";

const PromocodePackage = withBreadcrumbs(({isInline}) => {
    const {id} = useParams();
    let history = useHistory();
    const {createEntry, getEntry, editEntry} = useDefaultEndpoints('promocodes-packages');
    const [loading, setLoading] = useState(true);
    const {handleSubmit, control, errors, reset} = useForm({reValidateMode: 'onBlur',});
    const permissions = useRoleSystem(ENTITIES.PROMOCODE_PACKAGES);
    const [state, setState] = useState({
        comment: '',
        prefix: '',
        category: 'simple',
        available_from: dayjs().startOf('day').toDate(),
        available_to: dayjs().add(1, 'M').startOf('day').toDate(),
        is_open_ended: false,
        used_total: 0,
        is_extinguished: false,
        is_archived: false,
        discount_type: 'summ',
        discount_currency: 'RUB',
        applicable_to: [],
    });

    useEffect(() => {
        if (id)
            getEntry(id).then(res => {
                setState(() => ({
                    ...res,
                }))
                reset({prefix: res.prefix});
                setLoading(() => false);
            });
        else {
            setState((state) => ({
                ...state,
            }))
            setLoading(() => false);
            reset({prefix: ''});
        }
    }, [id]);

    const handleInput = e => {
        const {name, value} = handleInputChange(e)
        setState(state => ({...state, [name]: value}))
    };

    const createOrUpdate = (data) => {
        return id
            ? editEntry(id, {...state, ...data}).then(res => toast.success(t('management.promoCodePackages.success')))
            : createEntry({...state, ...data}).then(res => history.push('/administration/promocodes-packages'))
    }


    const submit = handleSubmit((data) => {
        createOrUpdate(data)
    }, data => console.log(data))

    return loading
        ? <Loader/>
        : <TableCard isInline={isInline}>
            <Form>
                {!id && <div className={'row justify-content-end mb-0 pr-0 mr-0'}>
                    <div className={'col-auto'}>
                        <ElementWithPermissions disabled={!permissions.edit}>
                            <button className={'btn btn-sm btn-primary btn-rounded'} onClick={submit}>
                                {t('management.promoCodePackages.create')}
                            </button>
                        </ElementWithPermissions>
                    </div>
                </div>}
                <Row className={'mb-3'}>
                    <Col xs={12}>
                        <h4 className={'display-4'}>{t('management.promoCodePackages.packageSetup')}</h4>
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Col xs={4}>
                        <FormGroup>
                            <TextControllerRHF control={control} inputName={'prefix'} setValue={handleInput}
                                               isRequired={true} placeholder={'MEGAFON_PARTNER'}
                                               disabled={!!id}
                                               defaultValue={state.prefix || ''}
                                               label={t('management.promoCodePackages.prefix')} errors={errors} rules={{required: t('management.promoCodePackages.requiredField')}}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={2}>
                        <FormGroup>
                            <TextControllerRHF control={control} inputName={'size'} setValue={handleInput}
                                               disabled={!!id}
                                               isRequired={true} placeholder={'100'} defaultValue={state.size || ''}
                                               label={t('management.promoCodePackages.packageSize')} errors={errors}
                                               rules={{required: t('management.promoCodePackages.requiredField')}}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Col xs={12}>
                        <h4 className={'display-4'}>{t('management.promoCodePackages.setupPromoCode')}</h4>
                    </Col>
                </Row>
                <Row className={'mb-4'}>
                    <Col xs={12}>
                        <Promocode extraState={state} isInline rhfConfig={{control, errors, handleSubmit, reset}}
                                   setStateCb={obj => setState(state => ({...state, ...obj}))}/>
                    </Col>
                </Row>
            </Form>
        </TableCard>
});

export default PromocodePackage;