import { Button, Table } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import ManagerCommentsForm from './Form';
import { t } from 'i18next';

const Item = ({ text, fullName, email, index }) => (
  <tr key={index}>
    <td>{text}</td>
    <td>{fullName}</td>
    <td>{email}</td>
  </tr>
);

const ManagerComments = ({ data, id, entity }) => {
  const [list, setList] = useState(data || []);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const isNotSingle = list.length > 1;
  const firstData = list[0];
  const otherData = isNotSingle && list.slice(1) || [];

  const onAdd = (value) => {
    setList([value, ...list]);
  }

  useEffect(() => {
    data && setList(data);
  }, [data]);

  return (
    <>
      <Table responsive>
        <thead>
        <tr>
          <th>{t('common.comment')}</th>
          <th>{t('common.fullName')}</th>
          <th>{t('common.email')}</th>
        </tr>
        </thead>
        <tbody>
        {firstData && <Item {...firstData} />}
        {isOpen && otherData.map((i, index) => (
          <Item {...i} index={index} />
        ))}
        </tbody>
      </Table>
      {isNotSingle && <>
        <Button className='mb-4' color="link" onClick={toggle}>
          {!isOpen ? t('common.showAll') : t('common.hide')}
        </Button>
      </>}
      <ManagerCommentsForm id={id} entity={entity} onAdd={onAdd} />
    </>
  );
}

export default ManagerComments;