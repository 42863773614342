import React, {useEffect, useMemo, useReducer, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {TableCard} from "../../../generic/cards";
import {Loader} from "../../../generic/loader";
import {Button, Col, Row} from "reactstrap";
import {FaCheck, FaTimes} from "react-icons/fa";
import EasyEdit from "react-easy-edit";
import {
    AdditionalInfo,
    AdventureCard,
    ClientFieldsBlock,
    IncludedBlock,
    MainInfoBlock,
    SwitchBlock,
    InsideInfoBlock, ReceiptInfoBlock
} from './AdventureBlocks'

import {initialState, reducer} from "./reducer";
import {API_URL} from "../../../../lib/api/useApi";
import {Controller, useForm} from "react-hook-form";
import FieldWithValidation from "../../../generic/fields/FieldWithValidation";
import {Promocodes} from "../../../bases/events/event/NewEventBlocks";
import {useRoleSystem} from "../../../../lib/utils/hooks/useRoleSystem";
import {ElementWithPermissions} from "../../../common/formElements";
import {t} from "i18next";
import { onNewPage, onRoleEntity } from '../helpers';

const Adventure = ({createTranslatedBreadcrumbsAndTitle, is_camp, is_charter}) => {
    const {url, type} = useParams();
    let history = useHistory();
    const [state, dispatch] = useReducer(reducer, initialState)
    const {getEntries, createEntry, editEntry} = useDefaultEndpoints('adventures', true);
    const getCurrencies = useDefaultEndpoints('currencies').getEntries;
    const [currencies, setCurrencies] = useState([])
    let entity = useMemo(() => onRoleEntity(state.is_school, state.is_camp, state.is_charter, type), [state.is_school, state.type, state.is_camp, state.is_charter]);
    const permissions = useRoleSystem(entity);
    const {handleSubmit, control, errors} = useForm({reValidateMode: 'onBlur',});

    useEffect(() => {
        if (url) {
            getEntries({url}).then(res => {
                dispatch({type: 'setForm', payload: {...res[0], files: {...res[0].img, preview: API_URL + res[0]?.img?.url}}})
                const mapper = {
                    url: {ru: res[0].name, en: res[0].url},
                    type: {ru: res[0].type === 'world' ? 'Мир' : 'Россия', en: res[0].type}
                }
                createTranslatedBreadcrumbsAndTitle
                && createTranslatedBreadcrumbsAndTitle(mapper, mapper)
            })
        }
        else {
            dispatch({type: 'refreshFields'})
            if (!state.id){
                dispatch({type: 'setLoading', payload: false})
            }
            const mapper = {
                type: {ru:type === 'world' ? 'мир' : 'россия', en: type}
            }
            createTranslatedBreadcrumbsAndTitle
            && createTranslatedBreadcrumbsAndTitle(mapper, mapper)
        }
        getCurrencies({}).then(res => setCurrencies(() => res));

    }, [url, type, state.id])

    const is_school = history.location.pathname.includes('school');

    const createOrUpdate = () => {
        const {active_promocodes_ids, ...rest} = state
        if (!rest.check_id) rest.check_id = null;
        return (state.id
            ? editEntry(state.id, {...rest})
            : createEntry({...state, type, is_school: !!is_school, is_camp: !!is_camp, is_charter: !!is_charter}))
            .then(res => onNewPage(state.url, res.is_school, res.is_camp, res.is_charter, true, history, type));
    }

    const onSubmit = handleSubmit(() => { createOrUpdate()})
    return (state.loading
            ? <Loader/>
            : <TableCard>
                <Row className={'mb-5 justify-content-between align-items-center'}>
                    <Col className={'col-sm-auto col-12 flex-grow-1 col mb-3 mb-sm-0'}>
                        <Controller
                            control={control}
                            name="name"
                            defaultValue={state.name}
                            rules={{required: t('inputs.validation.required')}}
                            render={(
                                {value, onBlur, name, ref, onChange},
                                {invalid}
                            ) =>
                                <FieldWithValidation invalid={invalid} errors={errors}
                                                     name={name} isRequired={true}
                                >
                                    <div className={'display-3' + (invalid ? ' is-invalid ' : '')}
                                    >
                                        <EasyEdit
                                            type="text"
                                            onSave={(val) => {
                                                onChange(val)
                                                dispatch({type: 'setForm', payload: {name: val}})
                                            }}
                                            value={value}
                                            onBlur={onBlur}
                                            placeholder={t('adventures.edit.name') + " " + (is_school
                                                ? t('adventures.edit.practice') : t('adventures.edit.travel'))}
                                            saveButtonLabel={<FaCheck fill={'success'} stroke={'success'}/>}
                                            cancelButtonLabel={<FaTimes color={'secondary'}/>}
                                        />
                                    </div>
                                </FieldWithValidation>
                            }
                        />
                    </Col>
                    <Col className={'col-auto'}>
                        <ElementWithPermissions disabled={!permissions.edit}>
                            <Button className={'btn-rounded'} color={'success'} size={'sm'} onClick={onSubmit}>
                                {t('adventures.edit.save')}
                            </Button>
                        </ElementWithPermissions>
                    </Col>
                    <Col className={'col-auto'}>
                        <Button className={'btn-rounded ml-1'} color={'secondary'} size={'sm'}
                                onClick={() => history.goBack()}>
                            {t('adventures.edit.cancel')}
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={8}>
                        <h4 className={'display-4'}>{t('adventures.edit.main')}</h4>
                    </Col>
                </Row>

                <Row className={'pb-3'}>
                    <Col xs={12} md={8}>
                        <MainInfoBlock state={state} dispatch={dispatch} currencies={currencies} control={control}
                                       errors={errors} isSchool={is_school}/>
                    </Col>
                    <Col xs={12} md={4}>
                        <SwitchBlock state={state} dispatch={dispatch} currencies={currencies} control={control}
                                     errors={errors}/>
                    </Col>
                </Row>

                <Row className={'pb-3'}>
                    <Col xs={12}>
                        <ClientFieldsBlock state={state} dispatch={dispatch}/>
                    </Col>
                    <Col xs={12} md={4}>
                        <AdventureCard state={state} dispatch={dispatch} control={control} errors={errors}/>
                    </Col>
                </Row>

                <IncludedBlock state={state} dispatch={dispatch} control={control} errors={errors}/>

                <Row>
                    <Col xs={12} md={6}>
                        <InsideInfoBlock state={state} dispatch={dispatch} control={control} errors={errors}/>
                    </Col>
                    <Col xs={12} md={6}>
                        <ReceiptInfoBlock state={state} dispatch={dispatch} control={control} errors={errors}/>
                    </Col>
                </Row>

                <Row className={'pb-3'}>
                    <Col xs={12} md={6}>
                        <AdditionalInfo  state={state} dispatch={dispatch}/>
                    </Col>
                    <Col xs={12} md={6}>
                        <Promocodes state={state} dispatch={dispatch} productType={'adventures'}
                                    disabled={!permissions.edit}/>
                    </Col>
                </Row>
                <Row className={'mb-5 justify-content-end align-items-center'}>
                    <Col className={'col-auto'}>
                        <ElementWithPermissions disabled={!permissions.edit}>
                            <Button className={'btn-rounded'} color={'success'} size={'sm'} onClick={onSubmit}>
                                {t('adventures.edit.save')}
                            </Button>
                        </ElementWithPermissions>
                    </Col>
                    <Col className={'col-auto'}>
                        <Button className={'btn-rounded ml-1'} color={'secondary'} size={'sm'}
                                onClick={() => history.push('/adventures/' + type)}>
                            {t('adventures.edit.cancel')}
                        </Button>
                    </Col>
                </Row>
            </TableCard>
    )
}

export default Adventure;