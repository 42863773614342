import React, {useEffect, useState} from "react";
import {withBreadcrumbs} from "../../common/hoc/Breadcrumbs";
import {Card, CardBody} from "reactstrap";
import {useGlobalConfigApi} from "../../../lib/api/globalConfig";
import SwitchExtraButton from "./SwitchExtraButton";
import {t} from "i18next";

const Separate = withBreadcrumbs((props) => {
    const {getGlobalSettings, saveGlobalSettings} = useGlobalConfigApi('global-settings');
    const [globalSettings, setGlobalSettings] = useState({});

    useEffect(() => {
        getGlobalSettings().then((data) => {
            setGlobalSettings(data.config);
        })
    }, [])

    const onChange = (key, value) => {
        const valueObj = {...globalSettings, [key]: value};
        saveGlobalSettings(valueObj).then(() => {
            setGlobalSettings(valueObj);
        })
    }

    return (
        <Card body className='mb-2'>
            <CardBody>
                <SwitchExtraButton
                    value={globalSettings.insurance_available}
                    setValue={value => onChange('insurance_available', value)}
                    id='insurance_available'
                    label={t('globalSettings.separateFunctionality.enableCustomerInsurance')}
                />
                <SwitchExtraButton
                    value={globalSettings.promo_subscription_available}
                    setValue={value => onChange('promo_subscription_available', value)}
                    id='promo_subscription_available'
                    label={t('management.promoCodes.useForAbonements')}
                />
            </CardBody>
        </Card>

    )
})

export default Separate;