import React from 'react'
import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import FieldWithValidation from "../../../../../../generic/fields/FieldWithValidation";
import {Controller} from "react-hook-form";
import {DatePicker} from "../../../../../../generic/fields";
import {TimePickerRHF} from "../../../../../../common/reactHookFormsFields";
import {t} from "i18next";

const EditModalChildren = ({entity, setEntity, control, errors, handleInput}) => {
    return <Form>
        <Row>
            <Col xs={12}>
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <Label>{t('school.theory.editModalChildren.lessonStart')}</Label>
                        </Col>
                        <Col xs={6}>
                            <DatePicker placeholder={t('school.theory.lessonsTable.date')} selectedDay={entity.start_date}
                                        onDateChange={date => setEntity(entity => ({...entity, start_date: date}))}/>
                        </Col>
                        <Col xs={6}>
                            <TimePickerRHF setEntity={setEntity} control={control} errors={errors} name="start_time"
                                           defaultValue={entity.start_time}/>
                        </Col>
                    </Row>
                </FormGroup>
            </Col>
            <Col xs={10} md={6}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="theme"
                        defaultValue={entity.theme || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired type="text"
                                                 label={t('school.theory.lessonsTable.theme')} onBlur={onBlur}
                                                 value={value} placeholder={t('school.theory.editModalChildren.meeting')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col xs={2} md={2}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="lesson_number"
                        defaultValue={entity.lesson_number || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired type="text"
                                                 label={t('school.theory.editModalChildren.lessonNumber')}
                                                 onBlur={onBlur} value={value} placeholder="1"
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
        </Row>
    </Form>
}

export default EditModalChildren;
