import React from 'react';
import {Col, FormGroup, Row} from "reactstrap";
import {TextControllerRHF} from "../../../../generic/fields";
import {t} from "i18next";


const InsideInfoBlock = ({state, dispatch, control, errors, isArrival}) => {
    const setAdventureData = (e) => dispatch({type: 'setFormData', payload: e})
    return (
        <Row className={'pb-3'}>
            <Col xs={12} >
                <h4 className={'display-4'}>{t('adventures.edit.insideInfo')}</h4>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <TextControllerRHF inputName={'comment'}  setValue={setAdventureData}
                                       label={isArrival
                                           ? t('adventures.edit.arrivalComment')
                                           : t('adventures.edit.travelComment')
                                        }
                                       control={control}
                                       errors={errors}
                                       defaultValue={state.comment || ''}
                                       placeholder={''}
                                       hint={t('adventures.edit.commentHint')}
                                       rules={{maxLength: 120}}/>
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <TextControllerRHF inputName={'client_comment'}  setValue={setAdventureData}
                                       label={isArrival
                                           ? t('adventures.edit.arrivalClientComment')
                                           : t('adventures.edit.travelClientComment')
                                        }
                                       control={control}
                                       errors={errors}
                                       defaultValue={state.client_comment || ''}
                                       placeholder={''}
                                       hint={t('adventures.edit.commentHint')}
                                       rules={{maxLength: 150}}/>
                </FormGroup>
            </Col>
        </Row>
    )
}

export default InsideInfoBlock;