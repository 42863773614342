import React from 'react'
import {Col, CustomInput, Form, FormGroup, Label, Row} from "reactstrap";
import FieldWithValidation from "../../generic/fields/FieldWithValidation";
import {Controller} from "react-hook-form";
import {getProductOptionsMultiselect} from "../../administration/certificates/certificate/tabs/General/Parameters";
import {SelectControllerRHF, TextControllerRHF} from "../../generic/fields";
import {Constants} from "sv-common";
import {requiredRule} from "../../../lib/utils/helper";
import Select from "react-select";
import i18n, {t} from "i18next";
import ElementWithAccessInCountries from "../../../lib/utils/hocs/ElementWithAccessInCountries";


const PAYMENT_SYSTEMS = Constants.PAYMENT_SYSTEMS;

const EditModalChildren = ({entity, setEntity, control, errors, handleInput}) => {
    const {language} = i18n

    const setCertificateTypes = certificateTypes =>
        setEntity({
            ...entity,
            'certificate_types': (certificateTypes || []).map(certificateType => certificateType.value)
        })

    const productTypeOptionsMultiselect = getProductOptionsMultiselect(language)

    return <Form>
        <Row>
            <Col lg={4}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="name"
                        defaultValue={entity.name || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired={true}
                                                 label={t('directories.legalEntities.name')}
                                                 type="text" onBlur={onBlur} value={value}
                                                 placeholder={t('directories.legalEntities.namePlaceholder')}
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col lg={4}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="shop_id"
                        defaultValue={entity.shop_id || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors}
                                                 label={t('directories.legalEntities.shopIdLabel')}
                                                 name={name} isRequired={true}
                                                 hint={entity.type === PAYMENT_SYSTEMS.TINKOFF
                                                     ? t('directories.legalEntities.shopIdHint') : ''}
                                                 type="text" onBlur={onBlur} value={value}
                                                 placeholder="XXXXXXXXX-XXXXXXXXX-XXXXXXXXX-XXXXXXXXX"
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
            <Col lg={4}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="api_key"
                        defaultValue={entity.api_key || ''}
                        rules={{required: t('inputs.validation.required')}}
                        render={(
                            {value, onBlur, name, ref, onChange},
                            {invalid}
                        ) =>
                            <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired={true}
                                                 label={t('directories.legalEntities.apiKeyLabel')}
                                                 type="text" onBlur={onBlur} value={value}
                                                 placeholder="XXXXXXXXXXXXXXXXXX-XXXXXXXXX-XXXXXXXXX-XXXXXXXXX"
                                                 onChange={e => onChange(handleInput(e, setEntity).value)}
                            />
                        }
                    />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col xs={6} lg={4}>
                <FormGroup>
                    <Label>{t('directories.legalEntities.certificateType')}</Label>
                    <Select
                        name="certificate_type" isMulti options={productTypeOptionsMultiselect}
                        onChange={setCertificateTypes} menuPlacement={'top'}
                        value={(entity.certificate_types || []).map(val => productTypeOptionsMultiselect
                            .filter(option => option.value === val)[0])}
                    />
                </FormGroup>
            </Col>
            <Col xs={6} lg={4}>
                <FormGroup>
                    <SelectControllerRHF inputName={'type'} control={control} setValue={e => handleInput(e, setEntity)}
                                         isRequired label={t('directories.legalEntities.paymentSystem')}
                                         rules={requiredRule} defaultValue={entity.type || ''}
                                         errors={errors} options={[
                        <option value={''} disabled>{t('directories.legalEntities.chooseType')}</option>,
                        ...Object.keys(PAYMENT_SYSTEMS)
                            .map(k => <option key={k} value={PAYMENT_SYSTEMS[k]}>{PAYMENT_SYSTEMS[k]}</option>)
                    ]}
                    />
                </FormGroup>
            </Col>
            <ElementWithAccessInCountries hideInCountries={['EN']}>
                <Col xs={6} lg={4}>
                    <FormGroup>
                        <Controller
                            control={control}
                            name="insurance_id"
                            defaultValue={entity.insurance_id || ''}
                            render={(
                                {value, onBlur, name, ref, onChange},
                                {invalid}
                            ) =>
                                <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired={true}
                                                     label={t('directories.legalEntities.insuranceId')}
                                                     type="text" onBlur={onBlur} value={value}
                                                     placeholder={t('directories.legalEntities.insuranceIdPlaceholder')}
                                                     onChange={e => onChange(handleInput(e, setEntity).value)}
                                />
                            }
                        />
                    </FormGroup>
                </Col>
            </ElementWithAccessInCountries>
        </Row>
      <ElementWithAccessInCountries hideInCountries={['EN']}>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <CustomInput type="checkbox" className={'custom-switch'} id="subscription_available"
                           name="subscription_available" label={t('directories.legalEntities.subscriptionAvailable')}
                           checked={entity.subscription_available || false} onChange={e => handleInput(e, setEntity)}/>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col><h5 className="mb-3">{t('directories.legalEntities.orangeData')}</h5></Col>
        </Row>
        <Row>
          <Col lg={4}>
            <FormGroup>
              <TextControllerRHF inputName={'inn'} control={control} errors={errors}
                                 defaultValue={entity.inn || ''} placeholder={'1234567890'}
                                 setValue={e => handleInput(e, setEntity).value}
                                 label={t('directories.legalEntities.inn')}/>
            </FormGroup>
          </Col>
          <Col lg={8}>
            <FormGroup>
              <TextControllerRHF inputName={'od_private_key'} type={'textarea'} control={control} errors={errors}
                                 defaultValue={entity.od_private_key || ''}
                                 placeholder={'-----BEGIN RSA PRIVATE KEY-----'}
                                 setValue={e => handleInput(e, setEntity).value}
                                 label={t('directories.legalEntities.privateKey')}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <TextControllerRHF inputName={'od_public_cert'} type={'textarea'} control={control} errors={errors}
                                 defaultValue={entity.od_public_cert || ''}
                                 placeholder={'-----BEGIN CERTIFICATE-----'}
                                 setValue={e => handleInput(e, setEntity).value}
                                 label={t('directories.legalEntities.certificate')}/>
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <TextControllerRHF inputName={'od_private_cert'} type={'textarea'} control={control} errors={errors}
                                 defaultValue={entity.od_private_cert || ''}
                                 placeholder={'-----BEGIN PRIVATE KEY-----'}
                                 setValue={e => handleInput(e, setEntity).value}
                                 label={t('directories.legalEntities.certificateKey')}/>
            </FormGroup>
          </Col>
        </Row>
      </ElementWithAccessInCountries>
    </Form>
}

export default EditModalChildren;
